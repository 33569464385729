@media print {
  .App-header {
    display: none;
  }

  input {
    display: none;
  }

  .noPrint {
    display: none !important;
  }
}

@font-face {
  font-family: 'PrintClearlyDashed';
  src: url('../fonts/print-clearly-dashed.ttf') format('truetype');
}

.App-header {
  text-align: center;
  background-color: #282c34;
  color: white;
  font-size: 20px;
  padding: 4px 0;
}

.marginLeft {
  margin-left: 8px;
}

.settings {
  display: flex;
  margin: 16px;
  line-height: 24px;
  justify-content: space-evenly;
}

.settings button {
  margin: 0 8px;
  padding: 2px 8px;
  border: 1px solid #000;
}

.pageTitle {
  text-align: center;
  font-size: 48px;
}

.row {
  display: flex;
  justify-content: center;
  font-size: 64px;
  height: 48px;
  line-height: 48px;
  margin: 16px 32px;
}

.inputRow {
  display: flex;
  flex: 1;
  box-sizing: border-box;
  border-left: none;
  border-right: none;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-family: 'PrintClearlyDashed', sans-serif;
  font-size: 64px;
  height: 48px;
  line-height: 48px;
  padding: 0;
  position: relative;
  max-width: 7in;
  justify-content: center;
}

.row.spaceEvenly .inputRow {
  justify-content: space-evenly;
}

.inputRow .character {
  min-width: 8px;
}

.row input {
  background: rgba(208, 208, 208, 0.5);
  border: none;
  font-size: inherit;
  padding: 0;
  text-align: center;
}

.row input:focus-visible {
  outline: none;
}

.inputRow .middleLine {
  position: absolute;
  top: 22px;
  border-top: 1px dashed #999999;
  border-left: none;
  border-right: none;
  border-bottom: none;
  width: 100%;
}